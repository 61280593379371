interface AnalyticsWindowContext extends Window {
    plausible?: any;
}

interface AnalyticsEvent {
    name: string;
    props: unknown;
}

const windowContext: AnalyticsWindowContext = window;

const trackEvent = async (event: AnalyticsEvent) => {
    try {
        if (windowContext?.plausible) {
            windowContext.plausible(event.name, {
                props: event.props,
            });
        }
    } catch {
        /* NOP */
    }
};

export const trackFileDownload = async (url?: string, filename?: string) =>
    trackEvent({
        name: "File Download",
        props: {
            url,
            filename,
        },
    });
