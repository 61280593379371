import * as React from "react";
import { CSSProperties } from "react";
import {
    Cell,
    Column as BaseColumn,
    ColumnInstance,
    HeaderGroup,
    Row,
    TableCellProps,
    TableFooterProps,
    TableHeaderProps,
    TableRowProps,
    useExpanded,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import styled, { css } from "styled-components";
import Spacer from "../Spacer/Spacer";
import leftArrowIcon from "../../../../images/circle-arrow-left.svg";
import rightArrowIcon from "../../../../images/circle-arrow-right.svg";
import IconButton from "../buttons/IconButton/IconButton";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "../../../../styles/styles";

interface CustomColumnProps {
    headerStyle?: CSSProperties;
    style?: CSSProperties;
    Footer?: any;
}

export type Column<D extends Record<keyof D, unknown>> = BaseColumn<D> & CustomColumnProps;

interface Props<D extends Record<keyof D, unknown>> {
    columns: Column<D>[];
    data: D[];
    className?: string;
    renderRowSubComponent?: ({ row }: { row: Row<D> }) => React.ReactElement;
    isLoading?: boolean;
    showNoDataText?: boolean;
    caption?: string;
    noData?: React.ReactNode;
    toggleBtnCollapseAltTxt?: string;
    toggleBtnExpandAltTxt?: string;
    getColumnProps?: (column: HeaderGroup<D> | ColumnInstance<D>) => TableHeaderProps;
    getHeaderProps?: (header: HeaderGroup<D>) => TableHeaderProps;
    getRowProps?: (row: Row<D>) => Partial<TableRowProps>;
    getCellProps?: (cell: Cell<D>) => Partial<TableCellProps>;
    getFooterProps?: (column: HeaderGroup<D>) => TableFooterProps;
    showFooter?: boolean;
    headerIsSticky?: boolean;
    firstColIsSticky?: boolean;
    pagination?: {
        pageSize: number;
        labels: {
            prevButton: string;
            nextButton: string;
            page: string;
        };
    };
}

const StyledLoadingIndicator = styled(LoadingIndicator)`
    min-height: 100px;
`;

const noDataMsg = css`
    width: 100%;
    font-size: 18px;
    text-align: center;
`;

const PNoDataMsg = styled.p`
    ${noDataMsg}
`;

const columnBtn = css`
    background: transparent;
    border: none;
    font-weight: 700;
    color: ${styles.colors.grey7};
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    font-size: inherit;
`;

const ColumnBtn = styled.button`
    ${columnBtn}
`;

const tfootTd = css`
    font-weight: 700;
    color: ${styles.colors.grey7};
`;

const StyledTfoot = styled.tfoot`
    td {
        ${tfootTd}
    }
`;

const container = css`
    position: relative;
`;

const tdLink = css`
    color: ${styles.colors.grey7};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const tableStyles = css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;

    caption {
        line-height: 0;
        opacity: 0;
    }

    thead,
    tfoot {
        background-color: ${styles.colors.grey3};
        width: 100%;
    }

    th {
        text-align: left;
        padding: 7px 6px;
        font-size: 18px;
        width: auto;
        white-space: nowrap;

        :last-child {
            width: 100%;
        }
    }

    td {
        width: auto;
        padding: 10px 6px;
        font-size: 18px;
        vertical-align: top;
        white-space: nowrap;

        a {
            ${tdLink}
        }

        :last-child {
            width: 100%;
            white-space: normal;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid ${styles.colors.grey4};
        }
    }
`;

const TrNoDataMsgRow = styled.tr`
    border: none;
`;

const sorted = css`
    box-shadow: inset 0 3px 0 0 ${styles.colors.grey6};
`;

const sortedDesc = css`
    box-shadow: inset 0 -3px 0 0 ${styles.colors.grey6};
`;

const ToggleBtn = styled.button`
    background: transparent;
    border: none;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
`;

const toggleIcon = css`
    transform: rotate(-90deg);
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid ${styles.colors.grey7};
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    width: 0;
    height: 0;
    overflow: hidden;

    &::before {
        display: none;
    }
`;

const toggleIconOpen = css`
    ${toggleIcon};
    transform: rotate(0);
`;

const TdSubComponentContainer = styled.td`
    padding: 0;
`;

const TdToggleBtnContainer = styled.td`
    width: 20px;
    padding: 0;
`;

const StyledLoadingContainer = styled.div`
    min-height: 200px;
    width: 100%;
`;

const PaginationContainer = styled.div`
    margin: 15px 0;

    & > div {
        display: flex;
        align-items: center;
    }
`;

const stickyHeader = css`
    position: sticky;
    top: 0;
`;

const StickyHeader = styled.thead<{ isSticky: boolean }>`
    ${({ isSticky }) => isSticky && stickyHeader};
`;

const stickyFirstCol = css`
    .stickyHeader {
        z-index: 1;
    }

    tbody tr td:first-child,
    thead tr th:first-child {
        position: sticky;
        left: 0;
    }

    tbody tr td:first-child {
        background-color: ${styles.colors.white};
    }

    thead tr th:first-child {
        background-color: ${styles.colors.grey3};
    }
`;

export const TableContainer = styled.div`
    overflow: auto;
    width: 100%;
    height: 200px;
    max-height: 200px;
    margin: 2em auto;
`;

export const StyledTable = styled.table<{ firstColIsSticky: boolean }>`
    ${tableStyles};
    ${({ firstColIsSticky }) => firstColIsSticky && stickyFirstCol};
`;

const SortedHeader = styled.th<{ headerSortedClassName: any }>`
    ${({ headerSortedClassName }) => headerSortedClassName};
`;

// Create a default prop getter
const defaultPropGetter = () => ({} as any);

const NoDataRow = ({
    colSpan,
    noData,
}: {
    colSpan: number;
    noData: Props<Record<string, never>>["noData"];
}) => (
    <TrNoDataMsgRow data-testid="no-data-container">
        <TdSubComponentContainer colSpan={colSpan}>
            <PNoDataMsg>{noData}</PNoDataMsg>
        </TdSubComponentContainer>
    </TrNoDataMsgRow>
);

const getAriaSort = <D extends Record<keyof D, unknown>>(column: ColumnInstance<D>) =>
    column.isSorted ? (column.isSortedDesc ? "descending" : "ascending") : "none";

const headerSortedClassName = <D extends Record<keyof D, unknown>>(column: ColumnInstance<D>) =>
    column.isSorted ? (column.isSortedDesc ? sortedDesc : sorted) : undefined;

const ToggleButton = <D extends Record<keyof D, unknown>>({
    row,
    toggleBtnCollapseAltTxt,
    toggleBtnExpandAltTxt,
}: {
    row: Row<D>;
    toggleBtnCollapseAltTxt: Props<D>["toggleBtnCollapseAltTxt"];
    toggleBtnExpandAltTxt: Props<D>["toggleBtnExpandAltTxt"];
}) => (
    <TdToggleBtnContainer {...row.getToggleRowExpandedProps()} role="cell">
        <ToggleBtn aria-expanded={row.isExpanded} type="button">
            <img
                alt={row.isExpanded ? toggleBtnCollapseAltTxt : toggleBtnExpandAltTxt}
                className={row.isExpanded ? `${toggleIconOpen}` : `${toggleIcon}`}
            />
        </ToggleBtn>
    </TdToggleBtnContainer>
);

const LoadingContainer = ({ isLoading }: { isLoading: boolean }) => (
    <StyledLoadingContainer data-testid="loading-container">
        <StyledLoadingIndicator isLoading={isLoading} />
    </StyledLoadingContainer>
);

export function Table<D extends Record<keyof D, unknown>>({
    columns,
    data,
    isLoading,
    headerIsSticky,
    firstColIsSticky,
    showNoDataText,
    noData,
    className,
    caption,
    renderRowSubComponent,
    toggleBtnExpandAltTxt,
    toggleBtnCollapseAltTxt,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getFooterProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    showFooter,
    pagination,
}: Props<D>) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows, // All rows
        page, // Rows of the current page
        visibleColumns,
        footerGroups,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable<D>(
        {
            columns,
            data,
            ...(pagination ? { initialState: { pageSize: pagination.pageSize } } : {}),
        },
        useSortBy,
        useExpanded,
        ...(pagination ? [usePagination] : [])
    );

    const showNoData = !isLoading && Boolean(showNoDataText) && data.length === 0;

    if (isLoading) {
        return <LoadingContainer isLoading={isLoading} />;
    }

    const tableRows = pagination ? page : rows;

    return (
        <div className={className ? `${className} ${container}` : `${container}`}>
            <StyledTable firstColIsSticky={firstColIsSticky!} {...getTableProps()}>
                <caption>{caption}</caption>
                <StickyHeader
                    isSticky={headerIsSticky!}
                    className={`${headerIsSticky && "stickyHeader"}`}
                >
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {renderRowSubComponent && <th colSpan={1} role="columnheader"></th>}
                            {headerGroup.headers.map((column) => (
                                <SortedHeader
                                    headerSortedClassName={headerSortedClassName<D>(column)}
                                    aria-sort={getAriaSort<D>(column)}
                                    {...column.getHeaderProps([
                                        {
                                            style: column.headerStyle,
                                        },
                                        getColumnProps(column),
                                        getHeaderProps(column),
                                        column.getSortByToggleProps(),
                                    ])}
                                >
                                    <ColumnBtn type="button">{column.render("Header")}</ColumnBtn>
                                </SortedHeader>
                            ))}
                        </tr>
                    ))}
                </StickyHeader>
                <tbody {...getTableBodyProps()}>
                    {showNoData ? (
                        <NoDataRow colSpan={visibleColumns.length} noData={noData} />
                    ) : (
                        tableRows.map((row, index) => {
                            prepareRow(row);

                            return (
                                <React.Fragment key={`row-${index}`}>
                                    <tr {...row.getRowProps(getRowProps(row))}>
                                        {renderRowSubComponent && (
                                            <ToggleButton
                                                row={row}
                                                toggleBtnCollapseAltTxt={toggleBtnCollapseAltTxt}
                                                toggleBtnExpandAltTxt={toggleBtnExpandAltTxt}
                                            />
                                        )}
                                        {row.cells.map((cell, cellIndex) => (
                                            <td
                                                {...cell.getCellProps([
                                                    {
                                                        style: {
                                                            minWidth: `${cell.column.minWidth}px`,
                                                            maxWidth: `${cell.column.maxWidth}px`,
                                                            width: `${cell.column.width}px`,
                                                            ...cell.column.style,
                                                        },
                                                    },
                                                    getColumnProps(cell.column),
                                                    getCellProps(cell),
                                                ])}
                                                role={cellIndex === 0 ? "rowheader" : "cell"}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                    {renderRowSubComponent && row.isExpanded && (
                                        <tr>
                                            <TdSubComponentContainer
                                                colSpan={visibleColumns.length + 1}
                                            >
                                                {renderRowSubComponent({ row })}
                                            </TdSubComponentContainer>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    )}
                </tbody>
                {showFooter && (
                    <StyledTfoot>
                        {footerGroups.map((group) => (
                            <tr {...group.getFooterGroupProps()}>
                                {renderRowSubComponent && <td colSpan={1}></td>}

                                {group.headers.map((column) => (
                                    <td
                                        {...column.getFooterProps([
                                            {
                                                style: column.headerStyle,
                                            },
                                            getColumnProps(column),
                                            getFooterProps(column),
                                        ])}
                                    >
                                        {column.render("Footer")}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </StyledTfoot>
                )}
            </StyledTable>
            {pagination && (
                <PaginationContainer>
                    <Spacer horizontal={"xl"}>
                        <IconButton
                            icon={leftArrowIcon}
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            label={pagination.labels.prevButton}
                            ariaLabel={pagination.labels.prevButton}
                        />
                        <IconButton
                            icon={rightArrowIcon}
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            label={pagination.labels.nextButton}
                            ariaLabel={pagination.labels.nextButton}
                        />
                        <span>{`${pagination.labels.page} ${pageIndex + 1}/${pageCount}`}</span>
                    </Spacer>
                </PaginationContainer>
            )}
        </div>
    );
}

export { Cell, HeaderGroup, Row } from "react-table";
