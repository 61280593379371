import React from "react";
import {
    documentToReactComponents,
    Options as RawOptions,
} from "@contentful/rich-text-react-renderer";

import {
    SegmentedDocument as SegmentedDocumentType,
    LocalizedDocument,
    ContentfulDocumentEmbeddedContent,
    DocumentContentBlock,
    AuthenticatedFeature as AuthenticatedFeatureType,
    Media,
    DocumentFileList,
    ColumnRow as ColumnRowType,
    DocumentSection as DocumentSectionType,
    ActionSteps as ActionStepsType,
    Section as SectionType,
    ContactList,
    IframeContent as IframeContentType,
    Table as TableType,
    RoleList as RoleListType,
    LinkButton as LinkButtonType,
    VersionHistoryBlock as VersionHistoryType,
    CtaBlock as CtaBlockType,
} from "interfaces/content";

import { Locale } from "services/common/localization/localization";
import { Block, Inline } from "interfaces/rich-text-types";
import useLocale from "hooks/common/useLocale/useLocale";
import AuthenticatedFeature from "../AuthenticatedFeature/AuthenticatedFeature";
import DocumentList from "../DocumentList/DocumentList";

import Section from "../Section/Section";
import RichTextContactList from "../RichTextContactList/RichTextContactList";
import IframeContent from "../IframeContent/IframeContent";
import Table from "../Table/Table";
import ColumnRow from "components/content/ColumnRow/ColumnRow";
import DocumentSection from "components/content/DocumentSection/DocumentSection";
import ActionSteps from "components/content/ActionSteps/ActionSteps";
import LinkButton from "components/content/LinkButton/LinkButton";
import RoleList from "components/content/RoleList/RoleList";
import styled from "styled-components";
import VersionHistoryBlock from "../VersionHistoryBlock/VersionHistoryBlock";
import CtaBlock from "../CtaBlock/CtaBlock";
import { InView } from "react-intersection-observer";
import { GROUP_DELIM, SectionNavContext } from "../SectionNav/SectionNavContext";
import { StyledTable, TableContainer } from "components/common/ui/Table/Table";

export interface Props {
    id?: string;
    richText?: LocalizedDocument;
    localizedSegmentedContent?: SegmentedDocumentType;
    updateSectionNavContext?: boolean;
    "data-segment-id"?: string;
}

export const isAuthenticatedFeature = (
    entry: ContentfulDocumentEmbeddedContent
): entry is AuthenticatedFeatureType => entry.type === "authenticatedFeature";

export const isColumnRow = (entry: ContentfulDocumentEmbeddedContent): entry is ColumnRowType =>
    entry.type === "columnRow";

const isDocumentSection = (
    entry: ContentfulDocumentEmbeddedContent
): entry is DocumentSectionType => entry.type === "documentSection";

const isActionSteps = (entry: ContentfulDocumentEmbeddedContent): entry is ActionStepsType =>
    entry.type === "actionSteps";

const isLinkButton = (entry: ContentfulDocumentEmbeddedContent): entry is LinkButtonType =>
    entry.type === "linkButton";

export const isSection = (entry: ContentfulDocumentEmbeddedContent): entry is SectionType =>
    entry.type === "section";

const isAsset = (entry: ContentfulDocumentEmbeddedContent | Media): entry is Media =>
    entry.type === "media";

const isDocumentList = (entry: ContentfulDocumentEmbeddedContent): entry is DocumentFileList =>
    entry.type === "documentList";

const isContactList = (entry: ContentfulDocumentEmbeddedContent): entry is ContactList =>
    entry.type === "contactList";

const isIframeContent = (entry: ContentfulDocumentEmbeddedContent): entry is IframeContentType =>
    entry.type === "iframeContent";

const isTable = (entry: ContentfulDocumentEmbeddedContent): entry is TableType =>
    entry.type === "table";

const isRoleList = (entry: ContentfulDocumentEmbeddedContent): entry is RoleListType =>
    entry.type === "roleList";

const isVersionHistory = (entry: ContentfulDocumentEmbeddedContent): entry is VersionHistoryType =>
    entry.type === "versionHistory";

const isCtaBlock = (entry: ContentfulDocumentEmbeddedContent): entry is CtaBlockType =>
    entry.type === "ctaBlock";

type Segmented<T> = T & { segmentId: string };
const isSegmented = <T extends DocumentEmbeddedEntryContentBlock>(
    entry?: T
): entry is Segmented<T> => (entry ? "segmentId" in entry : false);

enum BLOCKS {
    DOCUMENT = "document",
    PARAGRAPH = "paragraph",
    HEADING_1 = "heading-1",
    HEADING_2 = "heading-2",
    HEADING_3 = "heading-3",
    HEADING_4 = "heading-4",
    HEADING_5 = "heading-5",
    HEADING_6 = "heading-6",
    OL_LIST = "ordered-list",
    UL_LIST = "unordered-list",
    LIST_ITEM = "list-item",
    HR = "hr",
    QUOTE = "blockquote",
    EMBEDDED_ENTRY = "embedded-entry-block",
    EMBEDDED_ASSET = "embedded-asset-block",
    TABLE = "table",
    TABLE_ROW = "table-row",
    TABLE_CELL = "table-cell",
    TABLE_HEADER_CELL = "table-header-cell",
}

const Container = styled.div`
    max-width: 100%;
    overflow: hidden;
`;

const EmbeddedImg = styled.img`
    max-width: 100%;
`;

const StickyTableContainer = styled(TableContainer)``;

interface Options {
    renderNode: {
        // Matches RawOptions's renderNode but includes our internal "node" definition.
        [k in BLOCKS]?: (node: Block | Inline, children: React.ReactNode) => React.ReactNode;
    };
}

export interface DocumentEmbeddedEntryContentBlock extends DocumentContentBlock {
    data: {
        target?: ContentfulDocumentEmbeddedContent;
    };
}

export interface DocumentEmbeddedAssetContentBlock extends DocumentContentBlock {
    data: {
        target?: Media;
    };
}

const getOptions = (locale: Locale): Options => ({
    renderNode: {
        [BLOCKS.TABLE]: (_node: DocumentContentBlock, children) => (
            <StickyTableContainer>
                <StyledTable firstColIsSticky={true}>
                    <tbody>{children}</tbody>
                </StyledTable>
            </StickyTableContainer>
        ),
        [BLOCKS.EMBEDDED_ENTRY]: (node: DocumentEmbeddedEntryContentBlock) => {
            const embeddedEntry = node.data.target;
            const segmentId = isSegmented(node) ? node.segmentId : undefined;

            if (!embeddedEntry) {
                return <div />;
            }

            if (isAuthenticatedFeature(embeddedEntry)) {
                return <AuthenticatedFeature authenticatedFeature={embeddedEntry} />;
            }

            if (isDocumentList(embeddedEntry)) {
                return <DocumentList documentList={embeddedEntry} data-segment-id={segmentId} />;
            }

            if (isColumnRow(embeddedEntry)) {
                return <ColumnRow columnRow={embeddedEntry} />;
            }

            if (isDocumentSection(embeddedEntry)) {
                return (
                    <DocumentSection documentSection={embeddedEntry} data-segment-id={segmentId} />
                );
            }

            if (isActionSteps(embeddedEntry)) {
                return <ActionSteps actionSteps={embeddedEntry} />;
            }

            if (isLinkButton(embeddedEntry)) {
                return <LinkButton linkButton={embeddedEntry} />;
            }

            if (isSection(embeddedEntry)) {
                return <Section section={embeddedEntry} data-segment-id={segmentId} />;
            }

            if (isContactList(embeddedEntry)) {
                return <RichTextContactList contactList={embeddedEntry} />;
            }

            if (isIframeContent(embeddedEntry)) {
                return <IframeContent iframe={embeddedEntry} />;
            }

            if (isTable(embeddedEntry)) {
                return <Table table={embeddedEntry} />;
            }

            if (isRoleList(embeddedEntry)) {
                return <RoleList roleList={embeddedEntry} />;
            }

            if (isVersionHistory(embeddedEntry)) {
                return <VersionHistoryBlock versionHistoryBlock={embeddedEntry} />;
            }

            if (isCtaBlock(embeddedEntry)) {
                return <CtaBlock ctaBlock={embeddedEntry} />;
            }

            ((_: never) => {
                throw new Error(`Unknown embedded entry ${embeddedEntry}`);
            })(embeddedEntry);
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: DocumentEmbeddedAssetContentBlock) => {
            const embeddedAsset = node.data.target;

            const file = embeddedAsset?.file[locale];

            if (!embeddedAsset || !file) {
                return <div />;
            }

            if (isAsset(embeddedAsset)) {
                if (file.contentType.startsWith("image")) {
                    return (
                        embeddedAsset.file[locale] && (
                            <EmbeddedImg
                                src={`${embeddedAsset.file[locale]!.url}?fm=jpg&fl=progressive`}
                                alt={
                                    embeddedAsset.description
                                        ? embeddedAsset.description[locale]
                                        : ""
                                }
                            />
                        )
                    );
                }

                if (file.contentType.startsWith("video")) {
                    return (
                        embeddedAsset.file[locale] && (
                            <video controls src={embeddedAsset.file[locale]!.url} />
                        )
                    );
                }

                if (file.contentType.startsWith("audio")) {
                    return (
                        embeddedAsset.file[locale] && (
                            <audio controls src={embeddedAsset.file[locale]!.url} />
                        )
                    );
                }

                return (
                    <a
                        href={embeddedAsset.file[locale]!.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {embeddedAsset.file[locale]!.fileName}
                    </a>
                );
            }

            ((_: never) => {
                throw new Error(`Unknown embedded asset ${embeddedAsset}`);
            })(embeddedAsset);
        },
    },
});

const resolveElement = (element: HTMLElement, depth = 0): HTMLElement => {
    if (element.dataset?.segmentId) {
        return element;
    }

    if (element.parentElement && depth < 3) {
        return resolveElement(element.parentElement, depth + 1);
    }

    return element;
};

/* The library @contentful/rich-text-react-renderer uses internally the enums from @contentful/rich-text-types.
    However, we are using our own cloned version of enums from @interfaces/rich-text-types in both the frontend & the backend.
    In Typescript two different enums values are incompatible even if they are an identical string.
    Therefore, the compiler doesn't allow using our own version of a document or options due to enums being incompatible.
    Hence the "as" keyword is used here as a trade-off, since the @contentful/rich-text-react-renderer library is used only in this component (RichText). */
function RichText({
    id,
    richText,
    localizedSegmentedContent,
    updateSectionNavContext,
    ...props
}: Props): React.ReactElement | null {
    const locale = useLocale();
    const options = getOptions(locale);
    const { setVisible, setInvisible } = React.useContext(SectionNavContext);

    const document: SegmentedDocumentType | undefined =
        localizedSegmentedContent ?? richText?.[locale];

    const wrappedDocument = React.useMemo(() => {
        const component = document && documentToReactComponents(document, options as RawOptions);

        if (!updateSectionNavContext) {
            return component;
        }

        const inViewCallback = (inView: boolean, entry: IntersectionObserverEntry) => {
            const element = resolveElement(entry.target as HTMLElement);
            const segmentId = element.dataset.segmentId;

            if (segmentId) {
                if (inView) {
                    setVisible(segmentId);
                } else {
                    setInvisible(segmentId);
                }
            }
        };

        return React.Children.map(component, (child, index) => {
            if (!child || !React.isValidElement(child)) {
                return child;
            }

            const segmentId = child.props["data-segment-id"];

            if (!segmentId) {
                return child;
            }

            return (
                <InView
                    threshold={0.01}
                    data-segment-id={`${segmentId}${GROUP_DELIM}${index}`}
                    as="div"
                    onChange={(inView, entry) => inViewCallback(inView, entry)}
                >
                    {child}
                </InView>
            );
        });
    }, [options, document, updateSectionNavContext, setVisible, setInvisible]);

    return (
        <Container id={id} data-segment-id={props["data-segment-id"]}>
            {wrappedDocument}
        </Container>
    );
}

export default RichText;
